<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-18 13:37:05
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-09-23 11:47:18
-->
<template>
  <div class="homePage">
    <div class="pub_search_section">
      <div class="formLine">
        <div class="city_box">
          <i class="el-icon-location"></i>
          选择城市:
          <el-cascader
            size="mini"
            v-model="searchForm.city"
            clearable
            :options="cityOptions"
            :props="{value:'id',label:'text'}"
            @change="handleTaskNameSearch"
          ></el-cascader>
        </div>
        <div class="searchBox">
          <el-input size="mini" clearable placeholder="请输入您要的相关任务" v-model="searchForm.taskName">
            <template slot="prepend">
              <i class="el-icon-search"></i>
            </template>
            <template slot="append">
              <span @click="handleTaskNameSearch">搜索</span>
            </template>
          </el-input>
        </div>
        <a href="https://www.grejob.com/" target="_blank" class="link_to_enterprise"></a>
        <div class="hotWords">
          <span>热门搜索：</span>
          <span
            v-for="(item,index) in hotWords"
            :key="'hotWords'+index"
            :class="{active:searchForm.jobCateName == item.name}"
            @click="searchForm.jobCateName = item.name;handleTaskNameSearch()"
          >{{item.name}}</span>
        </div>
      </div>
    </div>

    <div class="main_section">
      <div class="cate_section">
        <ul>
          <template v-for="(item,index) in type">
            <li :key="'type'+index" :class="{hot:index<2}" v-if="index<=6">
              <router-link :to="'./tasks?tab='+item.id" class="text-ellipsis">{{item.label}}</router-link>
              <ul v-if="item.children">
                <template v-for="(item2,index2) in item.children">
                  <li :key="'type-children'+index2" v-if="index2<=2">
                    <router-link
                      :to="'./tasks?jobCategory='+JSON.stringify({id:item2.id,label:item2.label})"
                      class="text-ellipsis"
                    >{{item2.label}}</router-link>
                  </li>
                </template>
              </ul>
            </li>
          </template>
        </ul>
      </div>
      <el-carousel :interval="3000" arrow="always">
        <el-carousel-item v-for="(item,index) in bannerList" :key="'banner'+index">
          <a
            :href="item.url"
            target="_blank"
            v-if="item.url==undefined||(item.url&&(item.url.startsWith('http://')||item.url.startsWith('https://')))"
          >
            <img :src="item.pic" />
          </a>
          <router-link :to="item.url" v-else>
            <img :src="item.pic" />
          </router-link>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="list">
      <div class="title">
        热门任务
        <router-link to="/tasks">查看更多></router-link>
      </div>
      <el-row :gutter="12" v-if="list.length">
        <el-col :span="8" v-for="(item,index) in list" :key="'taskItem'+index">
          <a href="javascript:void(0);" class="index_task_item" @click="handleDetail(item)">
            <div class="line_1">
              <span class="name text-ellipsis">{{item.ecompanyJobCategoryName}}</span>
              <span class="city text-ellipsis">{{item.cityName?item.cityName:"不限"}}</span>
            </div>
            <div class="line_2 text-ellipsis" v-html="item.taskName"></div>
            <div class="line_3 text-ellipsis">
              {{item.taskMoney=="费用面议"?"":item.amount}}
              <span
                :class="{only:item.amount==undefined||item.amount.length == 0||item.taskMoney == '费用面议'}"
              >
                <template v-if="item.taskMoney == '费用面议'">费用面议</template>
                <template v-else-if="item.taskMoney == '固定价格'">/元</template>
                <template v-else-if="item.taskMoney == '按月'">元/月</template>
                <template v-else-if="item.taskMoney == '按周'">元/周</template>
                <template v-else-if="item.taskMoney == '按天'">元/天</template>
                <template v-else-if="item.taskMoney == '按时'">元/小时</template>
              </span>
            </div>
            <div class="line_4">发布时间:{{sliceData(item.publishDate)}}</div>
            <template v-if="item.isApply">
              <el-button type="warning" v-if="item.employeeTaskStatus == 'applying'">申请中</el-button>
              <el-button type="primary" v-else-if="item.employeeTaskStatus == 'doing'">进行中</el-button>
              <el-button
                type="success"
                v-else-if="item.employeeTaskStatus == 'finish'|| item.employeeTaskStatus == 'nopass' || item.employeeTaskStatus == 'cancelled'"
              >申请</el-button>
            </template>
            <template v-else>
              <el-button
                type="success"
                v-if="item.applyStatus == 'applying' && item.employeeTaskStatus != 'nopass'"
              >申请</el-button>
              <el-button type="info" v-if="item.employeeTaskStatus == 'nopass'">不可申请</el-button>
              <el-button
                type="info"
                v-if="item.applyStatus == 'stop' && item.employeeTaskStatus != 'nopass'"
              >已截止</el-button>
            </template>
            <div class="type_end" v-if="item.applyStatus == 'stop'"></div>
          </a>
        </el-col>
      </el-row>
      <template v-else>
        <empty text="暂无任务"></empty>
      </template>
    </div>
  </div>
</template>

<script>
import empty from "@/components/empty/index.vue";
import api from "@/api/list";
import jwt_decode from "jwt-decode";
import { setToken } from "@/utils/auth";
import { getToken } from "@/utils/auth";
export default {
  name: "home",
  components: {
    empty,
  },
  data() {
    return {
      cityOptions: [],
      listLoading: false,
      searchForm: {
        taskName: "",
        jobCateName: "",
        city: [],
      },
      bannerList: [],
      list: [],
      type: [],
      hotWords: [
        { id: 3, name: "市场推广" },
        { id: 4, name: "技术研发" },
        { id: 5, name: "企业咨询" },
      ],
    };
  },
  mounted() {
    //是否为企业端跳转自动登录的情况
    let eToken = this.$route.query.token;
    if (eToken) {
      setToken(eToken);
      this.$store.commit("SET_TOKEN", eToken);
      this.$store.commit("SET_JWTDECODE", jwt_decode(eToken));
      if (JSON.stringify(this.$store.getters.user) === "{}") {
        this.$store.dispatch("getUserInfo");
      }
    }

    //获取任务类型
    api.jobCategoryTree().then((res) => {
      if (res && res.data) {
        this.type = res.data;
      }
    });
    //获取广告位 登录和未登录
    //  console.log("login"+JSON.stringify(getToken));
    if (getToken() !== "undefined" && getToken()) {
      api.getAD({}, { subUrl: "/PC-Index" }).then((res) => {
        if (res && res.data) {
          this.bannerList = res.data.map((v) => {
              v.pic = process.env.VUE_APP_FILE_PATH + v.pic;
          return v;
          });

          this.bannerList.sort((a, b) => {
              return a.orders - b.orders;
          });
        }
      });
    }else{
      api.getNoLoginAD({}, { subUrl: "/PC-Index" }).then((res) => {
        if (res && res.data) {
          this.bannerList = res.data.map((v) => {
            v.pic = process.env.VUE_APP_FILE_PATH + v.pic;
            return v;
          });

          this.bannerList.sort((a, b) => {
            return a.orders - b.orders;
          });
        }
      });
    }

    //获取城市列表
    api.getAllCity().then((res) => {
      if (res.data) {
        this.cityOptions = res.data;
      }
    });

    //获取任务列表
    api.hotTaskList().then((res) => {
      if (res.data) {
        this.list = res.data;
      }
    });
  },
  methods: {
    handleTaskNameSearch() {
      this.$router.push({
        path: "/tasks",
        query: {
          city: this.searchForm.city.toString(),
          taskName: this.searchForm.taskName.trim(),
          jobCateName: this.searchForm.jobCateName.trim(),
        },
      });
    },
    handleDetail(item) {
      this.$router.push({ path: "/tasksDetail", query: { taskId: item.id } });
      // if (item.isApply) {
      //   this.$router.push({
      //     path: "/userCenter/tasksDetails",
      //     query: { taskId: item.id },
      //   });
      // } else {
      //   this.$router.push({ path: "/tasksDetail", query: { taskId: item.id } });
      // }
    },
    sliceData(data) {
      if (data && data.length == 10) {
        return data.slice(5);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.main_section {
  width: 1210px;
  margin: 0 auto;
  overflow: hidden;
  .cate_section {
    width: 290px;
    height: 386px;
    float: left;
    background: #fff;
    padding: 34px 16px;

    & > ul {
      & > li {
        position: relative;
        margin-bottom: 24px;
        &.hot {
          & > a {
            background-color: #f2641e;
          }

          &:after {
            content: " ";
            width: 14px;
            height: 14px;
            position: absolute;
            right: -6px;
            top: 7px;
            background: url(../assets/icon_hot.png) center center no-repeat;
          }
        }
        & > a {
          display: inline-block;
          vertical-align: middle;
          text-align: center;
          font-size: 14px;
          color: #fff;
          background: #676d94;
          margin-right: 6px;
          padding: 0 4px;
          line-height: 20px;
          border-radius: 2px;
          width: 4.6em;
          &:hover {
            opacity: 0.7;
          }
        }

        & > ul {
          display: inline-block;
          vertical-align: middle;
          & > li {
            display: inline-block;
            vertical-align: middle;
            margin-right: 6px;
            & > a {
              font-size: 14px;
              color: #666;
              max-width: 4em;
              line-height: 20px;

              &:hover {
                color: #f2641e;
              }
            }
          }
        }
      }
    }
  }

  /deep/.el-carousel {
    width: 902px;
    height: 386px;
    border-radius: 2px;
    overflow: hidden;
    background: #fff;
    float: right;
    .el-carousel__container,
    a,
    img {
      position: relative;
      width: 100%;
      height: 100%;
    }
    a {
      img {
        object-fit: cover;
      }
    }
  }
}

.list {
  width: 1210px;
  margin: 0 auto;

  .title {
    font-size: 24px;
    color: #333;
    line-height: 66px;
    padding: 0 20px;
    a {
      float: right;
      font-size: 16px;
      color: #666;
    }
  }
}
.index_task_item {
  position: relative;
  display: block;
  background: #fff;
  padding: 16px 30px;
  height: 200px;
  margin-bottom: 12px;
  top: 0;
  transition: top 500ms;
  -webkit-transition: top 500ms;

  &:hover {
    top: -6px;
  }

  .type_end {
    position: absolute;
    right: 30px;
    top: 20px;
    width: 92px;
    height: 92px;
    background: url(../assets/end.png) center center no-repeat;
  }

  .line_1 {
    height: 40px;
    line-height: 40px;
    color: #333;
    .name {
      display: inline-block;
      vertical-align: text-bottom;
      font-size: 22px;
      color: #333;
      margin-right: 10px;
      max-width: 8em;
    }

    .city {
      display: inline-block;
      vertical-align: text-bottom;
      font-size: 18px;
      color: #666;
      max-width: 10em;
    }
  }

  .line_2 {
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    color: #999;
  }
  .line_3 {
    height: 40px;
    line-height: 40px;
    font-size: 24px;
    color: #f2641e;
    span {
      font-size: 14px;

      &.only {
        font-size: 22px;
      }
    }
  }
  .line_4 {
    font-size: 14px;
    color: #999;
    margin-top: 36px;
  }

  /deep/.el-button {
    position: absolute;
    right: 30px;
    bottom: 20px;
    width: 100px;
    border-radius: 0;
    font-size: 16px;
  }
}
</style>